import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { FormSubmission } from "@/types/forms/general";
import {
  useInfiniteQuery,
  QueryKey,
  InfiniteData,
  UndefinedInitialDataInfiniteOptions,
} from "@tanstack/react-query";
import { useDocumentStore } from "@/lib/zustand/documentStore";
import { useFlags } from "launchdarkly-react-client-sdk";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

export interface UseInfiniteFormSubmissionsQueryReturn {
  data: FormSubmission[];
  nextPageKey: string | null;
}

interface UseInfiniteFormSubmissionsQueryParams {
  startDate: Date | null | undefined | string;
  endDate: Date | null | undefined | string;
  formId: string | undefined;
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<
      UseInfiniteFormSubmissionsQueryReturn,
      Error,
      InfiniteData<UseInfiniteFormSubmissionsQueryReturn>,
      QueryKey,
      { nextPageKey: string }
    >
  >;
}

export const useInfiniteFormSubmissions = (
  params: UseInfiniteFormSubmissionsQueryParams
) => {
  const { fetchWithAuth } = useAuthContext();
  const { formId, endDate, startDate, options } = params;
  const { webServerFormSubmissionFilters } = useFlags();
  const { serverFormColumnFiltersState, serverFormColumnOrderState } =
    useDocumentStore((state) => ({
      serverFormColumnFiltersState: state.serverFormColumnFiltersState,
      serverFormColumnOrderState: state.serverFormColumnOrderState,
    }));
  const cleanedKeys = Object.entries({
    ...serverFormColumnFiltersState,
    ...serverFormColumnOrderState,
  })
    .filter(([key, value]) => !!key && !!value)
    .reduce((obj: Record<string, any>, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  const constructURL = (pageParam: { nextPageKey: string }) => {
    if (!webServerFormSubmissionFilters) {
      return `${timecardsBaseUrl}/forms/${formId}/submission/list?startDate=${startDate}&endDate=${endDate}&nextPageKey=${pageParam.nextPageKey}&local=true`;
    }
    const searchParams = Object.entries(cleanedKeys).map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value ?? "")}`;
    });
    const formattedSearchParams =
      searchParams.length > 0 ? searchParams.join("&") : "";
    return `${timecardsBaseUrl}/forms/${formId}/submission/search?startDate=${startDate}&endDate=${endDate}&nextPageKey=${pageParam.nextPageKey}&local=true&${formattedSearchParams}`;
  };

  return useInfiniteQuery<
    UseInfiniteFormSubmissionsQueryReturn,
    Error,
    InfiniteData<UseInfiniteFormSubmissionsQueryReturn>,
    QueryKey,
    { nextPageKey: string }
  >({
    queryKey: [
      QUERY_KEYS.FORM_SUBMISSIONS,
      { formId, startDate, endDate, ...cleanedKeys },
    ],
    queryFn: async (params) => {
      const { pageParam } = params;

      const apiRes = await fetchWithAuth({
        url: constructURL(pageParam),
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching form");
      }

      return apiRes.json();
    },
    initialPageParam: { nextPageKey: "" },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPageKey) {
        return {
          nextPageKey: lastPage.nextPageKey,
        };
      }
    },
    enabled: Boolean(startDate && endDate && formId),
    ...(options && { ...options }),
  });
};
